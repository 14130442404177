import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Theme from "../../../../theme/theme"
import Shape from "../../../svg/shape"

const IntroColumnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10rem;
  padding-top: 5rem;
  border-top: 0.6rem solid;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem;
    margin-top: 20rem;
    padding-top: 15rem;
  }
`

const IntroColumnsElement = styled.div`
  position: relative;
  min-width: 30rem;
`

const StyledShape = styled(Shape)`
  position: absolute;
  top: 20rem;
  right: 15rem;
  z-index: -1;

  path {
    stroke: ${props => props.color};
  }
`

const IntroColumnContent = styled.div`
  font-size: 2.3rem;
  line-height: 2.6rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 2.8rem;
    line-height: 4.8rem;
  }

  > *:first-of-type {
    font-family: "darker_grotesquebold", sans-serif;
    margin-bottom: 6rem;
  }
`

const IntroColumnTitle = styled.p`
  font-size: 1.8rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`

const IntroColumns = ({ introColumns, isLight, shapeColor }) => {
  if (!introColumns) return

  return (
    <IntroColumnsElement>
      <IntroColumnsContainer
        lineColor={isLight ? Theme.colors.text : Theme.colors.invertedText}
      >
        {introColumns &&
          introColumns.map(introColumn => (
            <div key={introColumn.contentful_id}>
              <IntroColumnTitle>{introColumn.title}</IntroColumnTitle>
              <IntroColumnContent>
                {documentToReactComponents(JSON.parse(introColumn.content.raw))}
              </IntroColumnContent>
            </div>
          ))}
      </IntroColumnsContainer>
      <StyledShape color={shapeColor} />
    </IntroColumnsElement>
  )
}

IntroColumns.propTypes = {
  introColumns: PropTypes.array,
  isLight: PropTypes.bool,
  shapeColor: PropTypes.string,
}

export default IntroColumns
