import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Section from "../../common/section"
import ContentRow from "./content/content-row"
import WorkItems from "./content/work-items"
import Theme from "../../../theme/theme"
import Shape from "../../svg/shape"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 8rem;
`

const ProjectContentTitle = styled.h2`
  font-size: 6rem;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 10rem;
    margin-bottom: 12rem;
  }
`

const WorkTitle = styled.h2`
  font-size: 6rem;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 10rem;
    margin-bottom: 12rem;
  }
`

const Shape1 = styled(Shape)`
  position: absolute;
  top: -10rem;
  right: 10rem;
  width: 14rem;
  height: 14rem;

  > div {
    background: ${props => props.color};
  }
`
const Shape2 = styled(Shape)`
  position: absolute;
  bottom: -12rem;
  left: -14rem;
  width: 22rem;
  height: 22rem;

  > div {
    background: ${props => props.color};
  }
`
const Shape3 = styled(Shape)`
  position: absolute;
  top: -5rem;
  left: -40%;
  width: 100%;
  opacity: 0.3;
  z-index: -1;

  > div {
    &:after {
      display: block;
      background: ${props => props.color};
      width: 100%;
      padding-bottom: 100%;
      border-radius: 50%;
      content: "";
    }
  }
`

const ContentSection = ({ project, isLight }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={isLight ? Theme.colors.text : Theme.colors.invertedText}
      threshold={0.2}
      sectionColor={Theme.colors.invertedText}
    >
      <ProjectContentTitle>{project.aboutProjectTitle}</ProjectContentTitle>
      <ContentRow contentRow={project.firstAboutProjectInformation} />
      <ContentRow
        contentRow={project.secondaryAboutProjectInformation}
        isReversed={true}
      >
        <Shape1 color={project.secondColor} type={8} />
        <Shape2 color={project.mainColor} type={8} />
        <Shape3 color={project.mainColor} type={8} />
      </ContentRow>
      <WorkTitle>{project.workTitle}</WorkTitle>
      <WorkItems workItems={project.workItems} />
      {project.projectInformation &&
        project.projectInformation.map(projectInformation => (
          <ContentRow
            contentRow={projectInformation}
            key={projectInformation.contentful_id}
          />
        ))}
    </StyledSection>
  )
}

ContentSection.propTypes = {
  project: PropTypes.object,
  isLight: PropTypes.bool,
}

export default ContentSection
