import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import IntroSection from "./intro-section"
import ContentSection from "./content-section"
import ImageSection from "./image-section"
import ContactSection from "../../common/contact/contact-section"
import useColor from "../../../utils/useColor"

const ProjectContentElement = styled.div`
  > section:last-child {
    margin-bottom: 0;
  }
`

const ProjectContent = ({ pageContext }) => {
  const { isLight } = useColor(pageContext.project.mainColor)

  return (
    <ProjectContentElement>
      <IntroSection project={pageContext.project} isLight={isLight} />
      <ContentSection project={pageContext.project} isLight={isLight} />
      <ImageSection project={pageContext.project} />
      {pageContext.project.contact && (
        <ContactSection
          content={pageContext.project.contact}
          contactPageSlug={pageContext.contactPageSlug}
        />
      )}
    </ProjectContentElement>
  )
}

ProjectContent.propTypes = {
  pageContext: PropTypes.object,
}

export default ProjectContent
