import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"
import Theme from "../../../theme/theme"
import { TRANSLATIONS } from "../../../utils/translations"
import Shape from "../../svg/shape"
import IntroColumns from "./content/intro-columns"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-bottom: 0 !important;
`

const IntroContent = styled.div`
  position: relative;
  margin: 5rem 0 0;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
    margin: 15rem 0 0;
  }
`

const ProjectContent = styled.div`
  max-width: 120rem;
`

const ProjectGatsbyImageContainer = styled.div`
  position: relative;
  max-width: 80rem;
  z-index: -1;
  margin: 5rem auto 0;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin: 0 auto;
  }

  > div:last-child {
    overflow: hidden;

    .gatsby-image-wrapper {
      border-top-right-radius: 27rem;
    }
  }
`

const ImageShape1 = styled(Shape)`
  position: absolute;
  left: -7rem;
  bottom: 4rem;
  width: 13rem;
  height: 13rem;
  z-index: 2;

  > div {
    background: ${props => props.color};
  }
`

const ImageShape2 = styled(Shape)`
  position: absolute;
  right: -10rem;
  bottom: -10rem;
  width: 25rem;
  height: 25rem;
  z-index: -1;

  > div {
    background: ${props => props.color};
  }
`

const ProjectTitle = styled.h1`
  position: relative;
  font-size: 6rem;
  line-height: 7rem;
  font-family: "darker_grotesquebold", sans-serif;
  margin-bottom: 4rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 10rem;
    line-height: 10rem;
    margin-bottom: 7rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 17rem;
    line-height: 17rem;
    margin-bottom: 10rem;
  }
`

const TitleShape = styled(Shape)`
  position: absolute;
  left: 10rem;
  top: -6rem;
  z-index: -1;
  transform: rotate(-180deg);

  path {
    stroke: ${props => props.color};
  }
`

const ProjectDesc = styled.div`
  max-width: 120rem;
  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 5rem;
  font-family: "darker_grotesquebold", sans-serif;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 5rem;
    line-height: 6rem;
    margin-bottom: 4rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 7rem;
    line-height: 8rem;
    margin-bottom: 5rem;
  }
`

const ProjectLabel = styled.p`
  font-size: 1.8rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`

const ProjectType = styled.p`
  font-size: 2.6rem;
  margin-bottom: 1rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }
`

const ProjectScope = styled.p`
  font-size: 2.6rem;
  margin-bottom: 1rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }
`

const ProjectLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26rem;
  height: 7rem;
  font-size: 2.4rem;
  font-family: "darker_grotesquebold", sans-serif;
  text-decoration: none;
  color: ${props => props.theme.colors.invertedText};
  background: ${props => props.theme.colors.text};
  border-radius: 1rem;
  border: 0.2rem solid ${props => props.theme.colors.text};
  padding-bottom: 0.8rem;
  margin-top: 5rem;
`

const IntroSection = ({ project, isLight }) => {
  return (
    <StyledSection
      pageColor={project.mainColor}
      textColor={isLight ? Theme.colors.text : Theme.colors.invertedText}
      invertedTextColor={
        isLight ? Theme.colors.invertedText : Theme.colors.text
      }
      threshold={0.1}
    >
      <IntroContent>
        <ProjectContent>
          <ProjectTitle>
            {project.title}
            <TitleShape color={project.secondColor} />
          </ProjectTitle>
          {project.shortDescription && (
            <ProjectDesc>
              {documentToReactComponents(
                JSON.parse(project.shortDescription.raw),
              )}
            </ProjectDesc>
          )}
          <ProjectLabel>
            {
              TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE]
                .projectInformation
            }
          </ProjectLabel>
          <ProjectType>
            <strong>
              {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].type}:{" "}
            </strong>
            {project.type}
          </ProjectType>
          <ProjectScope>
            <strong>
              {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].scope}:{" "}
            </strong>
            {project.scope && project.scope.map(scope => `${scope}, `)}
          </ProjectScope>
          <ProjectLink
            href={project.link}
            rel="nofollow noopener noreferrer"
            target="#blank"
          >
            {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].seeOnline}
          </ProjectLink>
        </ProjectContent>
        {project.mainImage && (
          <ProjectGatsbyImageContainer>
            <GatsbyImage image={project.mainImage.gatsbyImageData} />
            <ImageShape1 color={project.secondColor} type={8} />
            <ImageShape2 color={project.secondColor} type={8} />
          </ProjectGatsbyImageContainer>
        )}
      </IntroContent>
      <IntroColumns
        introColumns={project.introColumns}
        isLight={isLight}
        shapeColor={project.secondColor}
      />
    </StyledSection>
  )
}

IntroSection.propTypes = {
  project: PropTypes.object,
  isLight: PropTypes.bool,
}

export default IntroSection
