import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"

const StyledSection = styled(Section)`
  padding: 0 !important;
`

const BottomImage = styled(GatsbyImage)`
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 100%;
  }
`

const ImageSection = ({ project }) => {
  if (!project.bottomImage) return

  return (
    <StyledSection>
      <BottomImage
        image={project.bottomImage.gatsbyImageData}
        isBanner={true}
      />
    </StyledSection>
  )
}

ImageSection.propTypes = {
  project: PropTypes.object,
}

export default ImageSection
