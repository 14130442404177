import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import GatsbyImage from "../../../common/gatsby-image"

const WorkItemsElement = styled.div`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row dense;
    grid-row-gap: 22rem;
    grid-column-gap: 10rem;
    margin-bottom: 30rem;
  }
`

const SingleWorkItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    margin-bottom: 0;
  }
`

const WorkItemImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 15rem;

  > div {
    width: 15rem;
  }

  img {
    max-width: 100%;
  }
`

const SingleItemContent = styled.div`
  margin-top: 3rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-left: 6rem;
    margin-top: 0;
  }

  h3 {
    font-size: 3.6rem;
    line-height: 4.8rem;
    margin-bottom: 2rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 2.4rem;
    line-height: 3.2rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 2.8rem;
      line-height: 4.8rem;
    }
  }
`

const WorkItems = ({ workItems }) => {
  if (!workItems) return

  return (
    <WorkItemsElement>
      {workItems.map(workItem => (
        <SingleWorkItem key={workItem.contentful_id}>
          <WorkItemImage>
            <GatsbyImage image={workItem.image.gatsbyImageData} />
          </WorkItemImage>
          <SingleItemContent>
            <h3>{workItem.title}</h3>
            {documentToReactComponents(JSON.parse(workItem.content.raw))}
          </SingleItemContent>
        </SingleWorkItem>
      ))}
    </WorkItemsElement>
  )
}

WorkItems.propTypes = {
  workItems: PropTypes.array,
}

export default WorkItems
