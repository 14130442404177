import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import GatsbyImage from "../../../common/gatsby-image"

const ContentRowElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    margin-bottom: 16rem;
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-width: 100%;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    ${props =>
      props.$isReversed &&
      `
    order: 1;`}
    width: 50%;

    .gatsby-image-wrapper {
      height: auto !important;
      width: auto !important;
    }
  }
`

const ProjectContent = styled.div`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    width: 50%;

    ${props =>
      props.$isReversed
        ? `
          padding-right: 6rem;`
        : `
            padding-left: 6rem;
          `}
  }
   {
  }

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
    margin-top: 5rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 4.8rem;
      line-height: 5rem;
      margin-bottom: 3rem;
      margin-top: 0;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 8rem;
      margin-bottom: 5rem;
    }
  }

  p {
    font-size: 2.4rem;
    line-height: 3.2rem;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 2.8rem;
      line-height: 4.8rem;
    }
  }
`

const ContentRow = ({ contentRow, isReversed = false, children }) => {
  if (!contentRow) return

  return (
    <ContentRowElement>
      <ImageContainer $isReversed={isReversed}>
        {contentRow.image && (
          <GatsbyImage image={contentRow.image.gatsbyImageData} />
        )}
        {children}
      </ImageContainer>

      {contentRow.content && (
        <ProjectContent $isReversed={isReversed}>
          {documentToReactComponents(JSON.parse(contentRow.content.raw))}
        </ProjectContent>
      )}
    </ContentRowElement>
  )
}

ContentRow.propTypes = {
  project: PropTypes.object,
  isReversed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
}

export default ContentRow
