import React from "react"
import Viewport from "../common/viewport"
import ProjectContent from "../content/project/project-content"
import SEO from "../seo"

const Project = props => {
  const project = props.pageContext.project

  if (!project) return

  return (
    <Viewport>
      <ProjectContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default Project

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.project.title}
    description={pageContext.project.seoDescription}
    keywords={pageContext.project.seoKeywords}
  />
)
